import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { UserType } from '@ov-suite/models-idm';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: UserType,
      api: 'idmlink',
      createTitle: 'User Type',
      route: ['management/user-type'],
      tableTitle: 'User Types',
      tabs: [
        {
          title: 'Users',
          path: '/management/users',
        },
        {
          title: 'User Types',
          path: '/management/user-type',
        },
      ],
    }),
  ],
})
export class UserTypeModule {}
