<div class="main-content">
<ov-suite-hierarchy-tabs [tabs]="tabs"></ov-suite-hierarchy-tabs>
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'User'"
      [formClass]="formClass"
      [service]="userService"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
