import process from 'process';

export const apiEnvironment = {
  environment: process.env.ENVIRONMENT ?? 'dev',
  customer: process.env.CUSTOMER ?? 'ov',
  port: {
    idm: process.env.IDM_API_PORT ? Number(process.env.IDM_API_PORT) : 3332,
    admin: process.env.ADMIN_API_PORT ? Number(process.env.ADMIN_API_PORT) : 3333,
    warehouse: process.env.WAREHOUSE_API_PORT ? Number(process.env.WAREHOUSE_API_PORT) : 3334,
    yard: process.env.YARD_API_PORT ? Number(process.env.YARD_API_PORT) : 3335,
    execution: process.env.EXECUTION_API_PORT ? Number(process.env.EXECUTION_API_PORT) : 3336,
    ceramicPortal: process.env.CERAMIC_PORTAL_API_PORT ? Number(process.env.CERAMIC_PORTAL_API_PORT) : 3337,
    order: process.env.ORDER_API_PORT ? Number(process.env.ORDER_API_PORT) : 3338,
  },

  microServicePort: {
    idm: process.env.IDM_MICRO_PORT ? Number(process.env.IDM_MICRO_PORT) : 2332,
    admin: process.env.ADMIN_MICRO_PORT ? Number(process.env.ADMIN_MICRO_PORT) : 2333,
    warehouse: process.env.WAREHOUSE_MICRO_PORT ? Number(process.env.WAREHOUSE_MICRO_PORT) : 2334,
    yard: process.env.YARD_MICRO_PORT ? Number(process.env.YARD_MICRO_PORT) : 2335,
    execution: process.env.EXECUTION_MICRO_PORT ? Number(process.env.EXECUTION_MICRO_PORT) : 2336,
    ceramic: process.env.CERAMIC_PORTAL_MICRO_PORT ? Number(process.env.CERAMIC_PORTAL_MICRO_PORT) : 2337,
    order: process.env.ORDER_MICRO_PORT ? Number(process.env.ORDER_MICRO_PORT) : 2338,
  },
  database: {
    host: process.env.DATABASE_HOST ?? 'localhost',
    username: process.env.DATABASE_USERNAME ?? 'postgres',
    password: process.env.DATABASE_PASSWORD ?? 'postgres',
    idmLinkDatabase: process.env.DATABASE_IDM ?? 'idmlink',
    adminLinkDatabase: process.env.DATABASE_ADMIN ?? 'adminlink',
    warehouseLinkDatabase: process.env.DATABASE_WAREHOUSE ?? 'warehouselink',
    yardLinkDatabase: process.env.DATABASE_YARD ?? 'yardlink',
    executionLinkDatabase: process.env.DATABASE_EXECUTION ?? 'executionlink',
    ceramicPortalDatabase: process.env.DATABASE_CERAMIC ?? 'ceramicportal',
    orderLinkDatabase: process.env.DATABASE_ORDER ?? 'orderlink',
    publicationPrefix: process.env.DATABASE_PUB_PREFIX ?? 'linksuite',
  },
  flowable: {
    warehouse: {
      username: process.env.FLOWABLE_WAREHOUSE_USERNAME ?? 'devops-warehouse',
      password: process.env.FLOWABLE_WAREHOUSE_PASSWORD ?? 'OpenV104',
      url: process.env.FLOWABLE_WAREHOUSE_URL ?? 'https://dev.flowable.ovsuite.com/flowable-ui',
    },
    yard: {
      username: process.env.FLOWABLE_YARD_USERNAME ?? 'devops-yard',
      password: process.env.FLOWABLE_YARD_PASSWORD ?? 'OpenV104',
      url: process.env.FLOWABLE_YARD_URL ?? 'https://dev.flowable.ovsuite.com/flowable-ui',
    },
    execution: {
      username: process.env.FLOWABLE_EXECUTION_USERNAME ?? 'devops-warehouse',
      password: process.env.FLOWABLE_EXECUTION_PASSWORD ?? 'OpenV104',
      url: process.env.FLOWABLE_EXECUTION_URL ?? 'https://dev.flowable.ovsuite.com/flowable-ui',
    },
    order: {
      username: process.env.FLOWABLE_ORDER_USERNAME ?? 'devops-order',
      password: process.env.FLOWABLE_ORDER_PASSWORD ?? 'OpenV104',
      url: process.env.FLOWABLE_ORDER_URL ?? 'https://dev.flowable.ovsuite.com/flowable-ui',
    },
  },
  url: {
    idm: process.env.IDM_URL ?? 'http://10.0.2.2:3332',
    admin: process.env.ADMIN_URL ?? 'http://10.0.2.2:3333',
    warehouse: process.env.WAREHOUSE_URL ?? 'http://10.0.2.2:3334',
    yard: process.env.YARD_URL ?? 'http://10.0.2.2:3335',
    execution: process.env.EXECUTION_URL ?? 'http://10.0.2.2:3336',
    ceramicPortal: process.env.CERAMIC_PORTAL_URL ?? 'http://10.0.2.2:3337',
    order: process.env.ORDER_URL ?? 'http://10.0.2.2:3338',
  },
  cognito: {
    federated: process.env.COGNITO_FEDERATED ? process.env.COGNITO_FEDERATED === 'true' : false,
    region: process.env.COGNITO_REGION ?? `eu-central-1`,
    identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID ?? 'eu-central-1:3a3ebdc4-c8b7-4aac-839f-2336f4c43578',
    userPoolId: process.env.COGNITO_USER_POOL_ID ?? `eu-central-1_lH5BYgGC6`,
    accessKey: process.env.COGNITO_ACCESS_KEY_ID ?? `AKIA25V5LREB2UOALDPO`,
    secretAccessKey: process.env.COGNITO_SECRET_ACCESS_KEY ?? `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`,
    temporaryPassword: process.env.TEMPORARY_PASSWORD ?? 'Password123',
    minimumPasswordLength: process.env.MINIMUMN_PASSWORD_LENGTH ?? 12,
    emailDelivery: process.env.COGNITO_EMAIL_DELIVERY ? process.env.COGNITO_EMAIL_DELIVERY === 'true' : true,
    smsDelivery: process.env.COGNITO_SMS_DELIVERY ? process.env.COGNITO_SMS_DELIVERY === 'true' : true,
    clientId: process.env.COGNITO_CLIENT_ID ?? '1iuorn2s7t84iaeeds5d6hnkkh',
    bucket: process.env.COGNITO_BUCKET ?? 'ovsuitebucketdevelop-develop',
    bucketRegion: process.env.COGNITO_BUCKET_REGION ?? 'https://uun21udnjd.execute-api.eu-central-1.amazonaws.com/dev',
  },
  other: {
    awsLambdaTokenSecretKey: process.env.LAMBDA_TOKEN_SECRET_KEY ?? 'the!super-classified-secret3#code',
    awsCeramicDBSyncApi: process.env.CERAMIC_DBSYNC_SERVICE_API ?? `localhost/dev/sync`,
    awsCeramicDBSyncApiKey: process.env.CERAMIC_DB_SYNC_API_KEY ?? '',
    redis: {
      url: process.env.REDIS_URL ?? 'redis://localhost:6379',
    },
    awsApiGatewayId: process.env.AWS_API_GATEWAY_ID ?? 'ABCD1234TemporaryId',
  },
  inhance: {
    enabledModule: process.env.INHANCE_MODULE !== 'false',
    eventSQSUrl: process.env.INHANCE_EVENT_SQS_URL ?? 'https://sqs.af-south-1.amazonaws.com/031896474190/ppc-events-uat.fifo',
    sqsRegion: process.env.INHANCE_SQS_REGION ?? 'af-south-1',
    accessKey: process.env.INHANCE_SQS_ACCESS_KEY ?? 'AKIAQO3JNSJHDW5VAYCM',
    secretAccessKey: process.env.INHANCE_SQS_SECRET_ACCESS_KEY ?? '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'
  },
  workflow: {
    pickSlip: process.env.WORKFLOW_PICKSLIP ?? 'wh-pick-sheet',
  },
};
