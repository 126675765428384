import { Routes } from '@angular/router';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { AdminLayoutComponent, RouteInfo } from '@ov-suite/ui';
import { FeaturesConfig } from './features.config';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const angularRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('@ov-suite/authguard-angular').then(m => m.UnauthorizedModule),
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        data: { feature: { id: FeaturesConfig.ViewProfile } },
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
      },
      {
        data: { feature: { id: FeaturesConfig.ManageUsers } },
        path: 'management/users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
      },
      {
        data: { feature: { id: FeaturesConfig.ManageUsers } },
        path: 'management/user-type',
        loadChildren: () => import('./modules/user-type/user-type.module').then(m => m.UserTypeModule),
      },
      {
        data: { feature: { id: FeaturesConfig.ManageUsers } },
        path: 'management/personnel',
        loadChildren: () => import('./modules/personnel/personnel.module').then(m => m.PersonnelModule),
      },
      {
        data: { feature: { id: FeaturesConfig.ManageUsers } },
        path: 'management/personnel-position',
        loadChildren: () => import('./modules/personnel-position/personnel-position.module').then(m => m.PersonnelPositionModule),
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
      },
      {
        path: '**',
        component: AuthLayoutComponent,
      },
    ],
  },
];

export const sidebarRoutes: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'dashboard',
    icontype: 'fa fa-tachometer',
    type: 'link',
  },
  {
    path: '/management',
    title: 'User Management',
    type: 'sub',
    collapse: 'general-setup',
    icontype: 'fa fa-user-plus',
    children: [
      { path: 'user-type', title: 'User Types', ab: 'UT', permissionId: FeaturesConfig.ManageUsers },
      { path: 'users', title: 'Users', ab: 'US', permissionId: FeaturesConfig.ManageUsers },
    ],
  },
  {
    path: '/management',
    title: 'Personnel Management',
    type: 'sub',
    collapse: 'general-setup',
    icontype: 'fa fa-user-plus',
    children: [
      { path: 'personnel-position', title: 'Personnel Position', ab: 'UT' },
      { path: 'personnel', title: 'Personnel', ab: 'US' },
    ],
  },
];
