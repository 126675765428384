import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { UsersRoutes } from './users.routing';
import { UserListComponent } from './list/user-list.component';
import { UserAddOrEditComponent } from './add-or-edit/user-add-or-edit.component';
import { AppService } from '../../services/user-type/user-type.service';
import { UserSetPasswordModalComponent } from './user-set-password-modal/user-set-password-modal.component';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(UsersRoutes), FormsModule, UiModule],
  declarations: [UserListComponent, UserAddOrEditComponent, UserSetPasswordModalComponent],
  providers: [AppService],
})
export class UsersModule {}
