import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PagesRoutes } from './pages.routing';

import { LoginComponent } from './login/login.component';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(PagesRoutes), FormsModule, ReactiveFormsModule, NgbTooltipModule],
  declarations: [LoginComponent],
})
export class PagesModule {}
