import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@ov-suite/models-idm';
import { DataSources, OvAutoService } from '@ov-suite/services';
import { FieldMetadata, GenericHierarchy } from '@ov-suite/ov-metadata';
import { CreateOrEditComponent } from '@ov-suite/ui';
import swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user/user.service';
import { UserSetPasswordModalComponent } from '../user-set-password-modal/user-set-password-modal.component';

@Component({
  selector: 'ov-suite-user',
  templateUrl: './user-add-or-edit.component.html',
  styleUrls: ['./user-add-or-edit.component.scss'],
})
export class UserAddOrEditComponent extends CreateOrEditComponent<User> {
  formClass = User;

  metadata: FieldMetadata<User>;

  title = 'User';

  public dataSources: DataSources<GenericHierarchy> = {};

  constructor(
    public userService: UserService,
    public autoService: OvAutoService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private readonly modalService: NgbModal,
  ) {
    super(activatedRoute, 'idmlink');
    this.ovAutoService = autoService;
  }

  async onSaveUser() {
    await this.onSave();
    this.save.subscribe(savedSuccess => {
      if (savedSuccess) {
        this.router.navigate(['/management/users']);
      }
    });
  }

  onResetPassword() {
    if (window.confirm(`Are you sure you want to reset this user's password?`)) {
      this.userService
        .resetUserPassword(this.data.id)
        .then(() => {
          swal.fire({
            title: 'Success!',
            text: 'Password reset successfully',
            type: 'success',
            timer: 2000,
          });
        })
        .catch(err => {
          swal.fire({
            title: 'Something went wrong!',
            text: 'Password not updated.',
            footer: err.message,
            type: 'error',
          });
        });
    }
  }

  onSetPassword() {
    const modalRef = this.modalService.open(UserSetPasswordModalComponent);
    modalRef.componentInstance.userId = this.data.id;
  }
}
