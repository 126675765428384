import { Component } from '@angular/core';
import { User } from '@ov-suite/models-idm';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'ov-suite-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
  parentId?: number;

  // Class - Required
  formClass = User;

  tabs = [
    {
      title: 'Users',
      path: '/management/users',
    },
    {
      title: 'User Types',
      path: '/management/user-type',
    },
  ];

  constructor(public userService: UserService) {}
}
