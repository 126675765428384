export class CognitoUser {
  public id?: string;

  public username?: string;

  public name?: string;

  public familyName?: string;

  public preferredName?: string;

  public email?: string;

  public phoneNumber?: string;

  public avatarUrl?: string;

  constructor(
    id?: string,
    username?: string,
    name?: string,
    familyName?: string,
    preferredName?: string,
    email?: string,
    phoneNumber?: string,
    avatarUrl?: string,
  ) {
    this.id = id || null;
    this.username = username || null;
    this.name = name || null;
    this.familyName = familyName || null;
    this.preferredName = preferredName || '';
    this.email = email || null;
    this.phoneNumber = phoneNumber || null;
    this.avatarUrl = avatarUrl || '';
  }

  fromAWS(attr: Record<string, string>) {
    return new CognitoUser(attr.sub, '', attr.name, attr.family_name, attr.name, attr.email, attr.phone_number, attr.picture);
  }

  toAWS() {
    return {
      name: this.name,
      family_name: this.familyName,
      email: this.email,
      preferred_username: this.preferredName,
      phone_number: this.phoneNumber,
      picture: this.avatarUrl,
    };
  }
}
